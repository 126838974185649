
/**
 * Created by wangtong on 2022/2/4
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {PcImages} from "@/config";
import * as config from '@/config'

@Component({})
export default class PcHomeBackground extends Vue {
    PcImages = PcImages
    config = config
}
