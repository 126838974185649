
/**
 * Created by wangtong on 2022/2/4
 * 手机端首页默认样式
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import * as config from "@/config";

@Component({})
export default class MobileHomeBackground extends Vue {
    Policy = config.Policy
    MobileImages = config.MobileImages
    Device = config.Device

    is_show_tip = false
    is_loading = false
    timer: number | null = null

    alert = {
        visible: false,
        title: "",
        message: ""
    }

}
