
/**
 * Created by wangtong on 2022/2/4
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import * as config from "@/config";
import {OfficialWebsiteActionCons, OfficialWebsiteDownloadButtonTypeCons} from "@/constants";
import {Browser, Convert, Type} from "@sandan/febasic";
import is = Type.is;
import {OfficialWebsiteQuery} from "@/models";
import {CommonApi} from "@/api/common";
import EventUseCase from "@/usecase/EventUseCase";
import BaseAppDownloader from "@/components/app_downloader/BaseAppDownloader";

@Component({})
export default class MobileAppDownloader extends BaseAppDownloader {
    get browser(): string {
        return config.Device.is_ios ? "Safari" : "browser";
    }

    mounted() {
        switch (this.action) {
            case OfficialWebsiteActionCons.Upgrade.value:
            case OfficialWebsiteActionCons.Download.value:
                // Download App
                this.openOrDownloadApp()
                break
        }

        document.addEventListener("visibilitychange", this.onVisibilityChange)
    }

    destroyed() {
        document.removeEventListener("visibilitychange", this.onVisibilityChange)
    }

    onClickAndroid() {
        if (this.copyText) {
            Browser.copyToClipboard(Convert.parseString(this.copyText))
        }
        this.openOrDownloadApp(false)
    }

    onClickIOS() {
        if (this.copyText) {
            Browser.copyToClipboard(Convert.parseString(this.copyText))
        }
        this.openOrDownloadApp(true)
    }

    showIOSNotPublishedMessage() {
        this.alert.visible = true
        this.alert.message = config.IOS_TOAST_MESSAGE
    }

    showTip() {
        this.is_show_tip = true;
    }

    openOrDownloadApp(isIos: boolean = config.Device.is_ios) {
        if (isIos) {
            if (config.IOS_PUBLISHED) {
                if (config.Device.is_android_black_app) {
                    this.showTip();
                } else if (this.action == OfficialWebsiteActionCons.Upgrade.value || this.androidAppSchema == config.ANDROID_APP_SCHEMA) {
                    this.downloadApp(isIos);
                } else {
                    this.tryOpenApp(isIos);
                }
            } else {
                this.showIOSNotPublishedMessage()
            }
        } else {
            if (config.Device.is_android_black_app) {
                this.showTip();
            } else if (this.action == OfficialWebsiteActionCons.Upgrade.value || this.androidAppSchema == config.ANDROID_APP_SCHEMA) {
                this.downloadApp(isIos);
            } else {
                this.tryOpenApp(isIos);
            }
        }
    }

    openAppTimer: number | null = null
    openAppIsIos: boolean = false
    openAppSchema: string = ''

    onVisibilityChange() {
        if (document.visibilityState == 'hidden') {
            // Page becomes invisible
            if (this.openAppTimer) {
                this.stopOpenApp()
            }
        } else {
            // Page becomes visible
            this.stopOpenApp()
        }
    }

    stopOpenApp() {
        if (this.openAppTimer) {
            clearTimeout(this.openAppTimer);
            this.openAppTimer = null
        }
    }

    tryOpenApp(isIos: boolean = config.Device.is_ios) {

        // Try to jump to the local App, if it does not jump successfully after a certain time, it is considered that there is no such App locally
        this.stopOpenApp()
        this.openAppIsIos = isIos

        this.openAppTimer = setTimeout(() => {
            this.stopOpenApp()
            if (!document.hidden) {
                // No corresponding App locally
                this.downloadApp(isIos)
            }
        }, 2500);

        if (isIos) {
            this.openAppSchema = this.iosAppSchema;
        } else {
            this.openAppSchema = this.androidAppSchema;
        }
        window.location.href = this.openAppSchema
    }

    // Download App or jump to AppStore
    downloadApp(isIos: boolean = config.Device.is_ios) {
        if (isIos) {
            // Jump to AppStore
            window.location.href = config.IOS_APP_STORE;
        } else {
            // Local download Apk
            this.alert.visible = true
            this.alert.message = "Downloading APK"
            window.location.href = config.ANDROID_APP_APK;
        }
    }
}
