
/**
 * Created by wangtong on 2021/12/11
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import MobileHomeBackground from "@/components/home/MobileHomeBackground.vue";
import MobileAppDownloader from "@/components/app_downloader/MobileAppDownloader.vue";
import {OfficialWebsiteQuery} from "@/models";

@Component({
    components: {MobileAppDownloader, MobileHomeBackground}
})
export default class MobileHome extends Vue {
    @Prop() query?: OfficialWebsiteQuery
}
